<template>
  <div class="">
    <avue-crud
      :data="tableData"
      :option="indexOption"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
    
      <template slot="state" slot-scope="{ row }">
        <el-switch
              v-model="row.state"
              :active-value="1"
              :inactive-value="2"
              @change="onchangeStatus(row)"
            />
      </template>

      <template #header>
        <div class="flex align-items marginBottom10">
          <div class="marginRight10 marginBottom10"></div>
          <el-input
            size="small"
            v-model="searchData.name"
            clearable
            :maxlength="100"
            placeholder="输入模板名称"
            class="marginRight10 width300 marginBottom10"
          />
          <div class="marginRight10 marginBottom10">
            <el-button size="small" type="primary" @click="handleSearch"
              >查询</el-button
            >
          </div>
          <div class="marginBottom10">
            <el-button size="small" type="info" @click="handleReset"
              >重置</el-button
            >
          </div>
          <div class="marginLeft10 marginBottom10">
            <el-button size="small" type="primary" @click="handleCreate">
              新增
            </el-button>
          </div>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >
          编辑
        </el-button>
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >
          删除
        </el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { list, deleteNew,stateUp } from "@/api/freight/freight";
import { indexOption } from "./data";
export default {
  name: "index",
  computed: { 
    ...mapGetters(["getButton"]),
  },
  data() {
    return {
      button: {},
      indexOption,
      searchData: {
        name: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
    };
  },
  created() {
    // console.log(this.button)
  },
  methods: {
    handleCreate() {
      this.$router.push({
        path: "/manage/create",
        query: {
          type: "create",
        },
      });
    },
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleReset() {
      this.page.currentPage = 1;
      this.searchData = {
        name: "",
      };
      this.onLoad();
    },
    onchangeStatus(row) {
      stateUp({
        id: row.id,
        state: row.state,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.onLoad();
        }
      }).catch((error)=>{
        this.onLoad();
      });
    },
    handleEdit(row) {
      // // console.log(row)
      this.$router.push({
        path: "/manage/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    handleDelete(row) {
      // // console.log(row)
      this.$confirm("是否删除此运费?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    onLoad() {
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>